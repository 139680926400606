export const channelsRouteBasePath = "/channels";

export const channelsRouteNames = {
  root: () => "channels",
  details: {
    root: () => `${channelsRouteNames.root}.details`,
    adaptations: {
      root: () => `${channelsRouteNames.details.root()}.adaptations`,
      details: () => `${channelsRouteNames.details.adaptations.root()}.details`,
      history: () => `${channelsRouteNames.details.adaptations.root()}.history`,
    },
    info: () => `${channelsRouteNames.details.root()}.info`,
    history: {
      root: () => `${channelsRouteNames.details.root()}.history`,
      details: () => `${channelsRouteNames.details.history.root()}.details`,
    },
  },
};

export const channelsRoutes: any = {
  path: channelsRouteBasePath,
  component: () => import("@/modules/channels/views/layouts/Default.vue"),
  meta: {
    auth: true,
    scope: ["channels"],
  },
  children: [
    {
      path: channelsRouteBasePath,
      name: channelsRouteNames.root,
      component: () => import("@/modules/channels/views/ChannelsList.vue"),
    },
    {
      path: `${channelsRouteBasePath}/:id`,
      name: channelsRouteNames.details.root(),
      component: () => import("@/modules/channels/views/ChannelDetails.vue"),
    },
    {
      path: `${channelsRouteBasePath}/:id/adaptations`,
      name: channelsRouteNames.details.adaptations.root(),
      component: () => import("@/modules/channels/views/ChannelDetails.vue"),
    },
    {
      path: `${channelsRouteBasePath}/:id/adaptations/:adaptationId`,
      name: channelsRouteNames.details.adaptations.details(),
      component: () => import("@/modules/channels/views/AdaptationDetails.vue"),
    },
    {
      path: `${channelsRouteBasePath}/:id/adaptations/:adaptationId/history`,
      name: channelsRouteNames.details.adaptations.history(),
      component: () => import("@/modules/channels/views/AdaptationHistory.vue"),
    },
    {
      path: `${channelsRouteBasePath}/:id/info`,
      name: channelsRouteNames.details.info(),
      component: () => import("@/modules/channels/views/ChannelDetails.vue"),
    },
    {
      path: `${channelsRouteBasePath}/:id/history`,
      name: channelsRouteNames.details.history.root(),
      component: () => import("@/modules/channels/views/ChannelDetails.vue"),
    },
    {
      path: `${channelsRouteBasePath}/:id/history/:publicationId`,
      name: channelsRouteNames.details.history.details(),
      component: () =>
        import("@/modules/channels/views/PublicationDetails.vue"),
    },
  ],
};

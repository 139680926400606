import { authentik } from "@ecca/vue-auth-plugin";
import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { config } from "@/data/config";

const vapidKey = import.meta.env.VITE_FCM_TOKEN;
const apiKey = import.meta.env.VITE_FCM_API_KEY;
const projectId = import.meta.env.VITE_FCM_PROJECT_ID;
const messagingSenderId = import.meta.env.VITE_FCM_SENDER_ID;
const appId = import.meta.env.VITE_FCM_APP_ID;

const validateEnv = () => {
  if (!vapidKey || !apiKey || !projectId || !messagingSenderId || !appId) {
    throw new Error("FCM env vars not set");
  }
};

export const fcmInit = async () => {
  validateEnv();

  initializeApp({
    apiKey,
    projectId,
    messagingSenderId,
    appId,
  });

  const fcm = getMessaging();
  const broadcastChannel = new BroadcastChannel("notifications");

  const token = await getToken(fcm, {
    vapidKey,
  });

  await fetch(`${config.eccaApiUrl}v1/notifications/register`, {
    body: token,
    method: "POST",
    headers: {
      Authorization: `Bearer ${authentik.tokens?.access_token}`,
    },
  });

  onMessage(fcm, (message) => {
    broadcastChannel.postMessage(message.data);
  });
};

export const initMessaging = async () => {
  authentik.authorizationCallback = (isAuthenticated) => {
    if (isAuthenticated) {
      fcmInit().then(() => {
        authentik.authorizationCallback = undefined;
      });
    }
  };
};

const routeBaseName = "client";
export const clientRouteBasePath = `/${routeBaseName}`;

export const clientRoutesNames = {
  root: routeBaseName,
  brands: {
    root: `${routeBaseName}.brands`,
    details: () => `${clientRoutesNames.brands.root}.details`,
    list: () => `${clientRoutesNames.brands.root}.list`,
  },
  categories: {
    root: `${routeBaseName}.categories`,
    details: () => `${clientRoutesNames.categories.root}.details`,
    list: () => `${clientRoutesNames.categories.root}.list`,
  },
  markets: {
    root: `${routeBaseName}.markets`,
    details: () => `${clientRoutesNames.markets.root}.details`,
    list: () => `${clientRoutesNames.markets.root}.list`,
  },
  retailers: {
    root: `${routeBaseName}.retailers`,
    details: () => `${clientRoutesNames.retailers.root}.details`,
    list: () => `${clientRoutesNames.retailers.root}.list`,
  },
};

export const clientRoutes: any = [
  {
    path: clientRouteBasePath,
    component: () => import("@/modules/client/views/layouts/Default.vue"),
    meta: {
      auth: true,
      scope: ["client", "client:read", "client:write"],
    },
    children: [
      {
        path: `${clientRouteBasePath}`,
        name: clientRoutesNames.root,
        redirect: { name: clientRoutesNames.brands.list() },
      },
      {
        path: `${clientRouteBasePath}/brands`,
        name: clientRoutesNames.brands.list(),
        component: () => import("@/modules/client/views/BrandList.vue"),
      },
      {
        path: `${clientRouteBasePath}/brands/:brandId`,
        name: clientRoutesNames.brands.details(),
        component: () => import("@/modules/client/views/BrandDetails.vue"),
      },
      {
        path: `${clientRouteBasePath}/categories`,
        name: clientRoutesNames.categories.list(),
        component: () => import("@/modules/client/views/CategoriesList.vue"),
      },
      {
        path: `${clientRouteBasePath}/categories/:categoryId`,
        name: clientRoutesNames.categories.details(),
        component: () => import("@/modules/client/views/CategoryDetails.vue"),
      },
      {
        path: `${clientRouteBasePath}/markets`,
        name: clientRoutesNames.markets.list(),
        component: () => import("@/modules/client/views/MarketList.vue"),
      },
      {
        path: `${clientRouteBasePath}/markets/:marketId`,
        name: clientRoutesNames.markets.details(),
        component: () => import("@/modules/client/views/MarketDetails.vue"),
      },
      {
        path: `${clientRouteBasePath}/retailers`,
        name: clientRoutesNames.retailers.list(),
        component: () => import("@/modules/client/views/RetailersList.vue"),
      },
      {
        path: `${clientRouteBasePath}/retailers/:retailerId`,
        name: clientRoutesNames.retailers.details(),
        component: () => import("@/modules/client/views/RetailerDetails.vue"),
      },
    ],
  },
];

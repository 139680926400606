import { recoRoutes } from "./components/reco/routes";
import { sklRoutes } from "@/modules/content/components/skl/routes";
import { storyboardsRoutes } from "@/modules/content/components/storyboards/routes";
import { templatesRoutes } from "@/modules/content/components/templates/routes";

const routeBaseName = "content";

export const contentRouteBasePath = `/${routeBaseName}`;

export const contentRoutesNames = {
  root: routeBaseName,
  lists: {
    root: `${routeBaseName}.lists`,
    writtenContent: () => `${contentRoutesNames.lists.root}.written`,
    skl: () => `${contentRoutesNames.lists.root}.skl`,
    templates: () => `${contentRoutesNames.lists.root}.rich`,
    storyboards: () => `${contentRoutesNames.lists.root}.storyboards`,
    basicContent: () => `${contentRoutesNames.lists.root}.basic`,
    reco: () => `${contentRoutesNames.lists.root}.reco`,
  },
};

export const contentRoutes: any = [
  {
    path: contentRouteBasePath,
    component: () => import("@/modules/content/views/layouts/Default.vue"),
    meta: {
      auth: true,
      scope: [
        "admin",
        "contents:skl",
        "contents:storyboards",
        "contents:rich",
        "contents:written_content",
        "contents:reco",
      ],
    },
    children: [
      {
        path: contentRouteBasePath,
        name: contentRoutesNames.root,
        component: () => import("@/modules/content/views/ContentLists.vue"),
      },
      {
        path: `${contentRouteBasePath}/written`,
        name: contentRoutesNames.lists.writtenContent(),
        component: () => import("@/modules/content/views/ContentLists.vue"),
      },
      {
        path: `${contentRouteBasePath}/skl`,
        name: contentRoutesNames.lists.skl(),
        component: () => import("@/modules/content/views/ContentLists.vue"),
      },
      {
        path: `${contentRouteBasePath}/basic`,
        name: contentRoutesNames.lists.basicContent(),
        component: () => import("@/modules/content/views/ContentLists.vue"),
      },
      {
        path: `${contentRouteBasePath}/storyboards`,
        name: contentRoutesNames.lists.storyboards(),
        component: () => import("@/modules/content/views/ContentLists.vue"),
      },
      {
        path: `${contentRouteBasePath}/rich`,
        name: contentRoutesNames.lists.templates(),
        component: () => import("@/modules/content/views/ContentLists.vue"),
      },
      {
        path: `${contentRouteBasePath}/reco`,
        name: contentRoutesNames.lists.reco(),
        component: () => import("@/modules/content/views/ContentLists.vue"),
      },
    ],
  },
  { ...sklRoutes(contentRouteBasePath) },
  ...storyboardsRoutes(contentRouteBasePath),
  ...templatesRoutes(contentRouteBasePath),
  { ...recoRoutes(contentRouteBasePath) },
];

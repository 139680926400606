import { defineStore } from "pinia";
import { ComputedRef } from "vue";
import { ActionTypes } from "@/modules/content/components/templates/types";
import { IKeywordsCollection } from "@/modules/products/components/WrittenContentEditor/types";
import {
  IdType,
  IIntentPhase,
  IKeyword,
  WrittenContent,
  WrittenContentAITask,
} from "@/modules/types";

export type DialogModes = `create` | `edit`;

export type DialogStatusType = {
  delete: boolean;
  details: {
    status: boolean;
    mode?: DialogModes;
  };
  createNewAdaptation: boolean;
  createNewLocalization: boolean;
  editLocalization: boolean;
  editAdaptation: boolean;
  sklTable: boolean;
  createNewSkl: boolean;
  createNewReco: boolean;
  aiGeneration: boolean;
  aiProcess: boolean;
  aiConfirmCancel: boolean;
  deleteStrategyRecommendationFile: boolean;
  uploadStrategyRecommendation: boolean;
  updateConfigFields: boolean;
};

export type WrittenContentWidgetSection =
  | "skl"
  | "guidance"
  | "reco"
  | undefined;

interface IProductsState {
  isSidePanelExpanded: boolean;
  writtenContent: {
    aiTasks: WrittenContentAITask[];
    isAiGenerationOn: boolean;
    isAiGenerationInProgress: boolean;
    isAIGenerated: boolean;
    isAiGenerationPossible: boolean;
    isAiGenerationSuccess: boolean | null;
    isEditModeEnabled: boolean;
    isHighlightModeEnabled: boolean;
    activeElementId: IdType | null;
    activeElementName: string;
    dialogStatus: DialogStatusType;
    recommendationVisibility: boolean;
    dialogKey: number;
    dialogData: any;
    currentActionType?: ActionTypes;
    clickedElement?: WrittenContent;
    sklWidget: {
      isExpanded: boolean;
      dragSidePanel: boolean | null;
      widgetSection?: WrittenContentWidgetSection;
    };
    sklData: {
      raw: IKeyword[] | null;
      parsed: IKeywordsCollection[];
    };
    intentPhases: IIntentPhase[];
    intentPhasesToParse: IIntentPhase[];
    eCommercePerfectNameFields: eCommercePerfectNameFieldsType[];
  };
}

export type eCommercePerfectNameFieldsType = {
  id: IdType;
  text: string;
  title: string;
  placeholder: string;
};

const initialState: IProductsState = {
  isSidePanelExpanded: true,
  writtenContent: {
    aiTasks: [],
    isAiGenerationOn: true,
    isAIGenerated: false,
    isAiGenerationSuccess: null,
    isAiGenerationPossible: false,
    isAiGenerationInProgress: false,
    isEditModeEnabled: true,
    isHighlightModeEnabled: true,
    activeElementId: null,
    activeElementName: "",
    dialogKey: 0,
    recommendationVisibility: true,
    dialogStatus: {
      delete: false,
      details: {
        status: false,
      },
      createNewAdaptation: false,
      createNewLocalization: false,
      editLocalization: false,
      editAdaptation: false,
      sklTable: false,
      createNewSkl: false,
      createNewReco: false,
      aiGeneration: false,
      aiProcess: false,
      aiConfirmCancel: false,
      updateConfigFields: false,
      uploadStrategyRecommendation: false,
      deleteStrategyRecommendationFile: false,
    },
    dialogData: undefined,
    sklWidget: {
      isExpanded: false,
      dragSidePanel: null,
    },
    sklData: {
      raw: null,
      parsed: [],
    },
    intentPhases: [],
    intentPhasesToParse: [],
    eCommercePerfectNameFields: [
      {
        id: "",
        text: "",
        title: "",
        placeholder: "",
      },
    ],
  },
};

export const useProductsStore = defineStore("products", {
  state: (): IProductsState => ({ ...initialState }),
  getters: {
    getWrittenContentAITaskById: (state) => {
      return (writtenContentID: ComputedRef<string>) =>
        state.writtenContent.aiTasks.find((task) => {
          if (task.writtenContentId === writtenContentID.value) {
            return task;
          }
        });
    },
  },
  actions: {
    addWrittenContentAITask(item: WrittenContentAITask) {
      const task = { ...item, visible: true };

      this.writtenContent.aiTasks.push(task);
    },

    async setWrittenContentAITaskSuccess(
      writtenContentID: IdType,
      isSuccess: boolean,
    ) {
      return new Promise((resolve) => {
        const index = this.writtenContent.aiTasks.findIndex(
          (item: any) => item.writtenContentId === writtenContentID,
        );
        if (index !== -1) {
          this.writtenContent.aiTasks[index].isAiGenerationSuccess = isSuccess;
        }
        resolve(true);
      });
    },

    removeWrittenContentAITask(writtenContentID: IdType) {
      const index = this.writtenContent.aiTasks.findIndex(
        (item: any) => item.writtenContentId === writtenContentID,
      );
      if (index !== -1) {
        this.writtenContent.aiTasks.splice(index, 1);
      }
    },

    toggleSidePanel() {
      this.isSidePanelExpanded = !this.isSidePanelExpanded;
      if (this.writtenContent.sklWidget.isExpanded) {
        this.resetSklWidgetState();
      }
    },
    toggleSklWidget() {
      if (this.writtenContent.sklWidget.dragSidePanel === null)
        this.writtenContent.sklWidget.dragSidePanel = this.isSidePanelExpanded;

      this.writtenContent.sklWidget.isExpanded =
        !this.writtenContent.sklWidget.isExpanded;

      if (this.writtenContent.sklWidget.dragSidePanel)
        this.isSidePanelExpanded = !this.writtenContent.sklWidget.isExpanded;
    },
    hideWrittenContentRecommendations() {},
    showWrittenContentRecommendations() {},
    enableAIGenerationButton() {
      this.writtenContent.isAiGenerationPossible = true;
    },
    disableAIGenerationButton() {
      this.writtenContent.isAiGenerationPossible = false;
    },
    turnOffAIGenerationButton() {
      this.writtenContent.isAiGenerationOn = false;
    },
    turnOnAIGenerationButton() {
      this.writtenContent.isAiGenerationOn = true;
    },
    setWCGeneratedWithAi(isAIGenerated: boolean) {
      this.writtenContent.isAIGenerated = isAIGenerated;
    },
    resetSidePanelState() {
      this.isSidePanelExpanded = true;
    },
    resetSklWidgetState() {
      this.writtenContent.sklWidget.isExpanded = false;
      this.writtenContent.sklWidget.dragSidePanel = null;
    },
    toggleHighlightMode() {
      this.writtenContent.isHighlightModeEnabled =
        !this.writtenContent.isHighlightModeEnabled;
    },
    setEditMode(value: boolean) {
      this.writtenContent.isEditModeEnabled = value;
    },
    setActiveWrittenContentElement(id: IdType) {
      this.writtenContent.activeElementId = id;
    },
    resetActiveWrittenContentElement() {
      this.writtenContent.activeElementId = null;
    },
    resetAdapterCreatorDialogStatus() {
      this.writtenContent.dialogStatus = {
        ...initialState.writtenContent.dialogStatus,
      };
    },
    resetWrittenContentDialogStatus() {
      this.writtenContent.dialogKey++;
      this.writtenContent.dialogStatus = {
        ...initialState.writtenContent.dialogStatus,
      };
      this.writtenContent.dialogData = initialState.writtenContent.dialogData;
    },
    saveWrittenContent() {},
    exportWrittenContent() {},
    writtenContentUpdateByThor(writtenContentId: IdType) {},
    setECommercePerfectNameFields(fields: eCommercePerfectNameFieldsType[]) {
      this.writtenContent.eCommercePerfectNameFields = fields;
    },
    openModal({
      type,
      item,
      actionType,
      mode,
      payload,
    }: {
      type: keyof DialogStatusType;
      item?: WrittenContent;
      actionType?: ActionTypes;
      mode?: `create` | `edit`;
      payload?: any;
    }) {
      this.resetWrittenContentDialogStatus();
      this.writtenContent.currentActionType = actionType;
      this.writtenContent.clickedElement = item;
      if (payload) this.writtenContent.dialogData = payload;

      if (type === "details") {
        this.writtenContent.dialogStatus[type].status = true;
        if (mode) this.writtenContent.dialogStatus[type].mode = mode;
      } else {
        this.writtenContent.dialogStatus[type] = true;
      }
    },
  },
});

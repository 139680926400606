// Plugins
import { eccaComponents } from "@ecca/ui-components";
import { VueQueryPlugin } from "@tanstack/vue-query";
import type { App } from "vue";
import { authentikPluginSetup } from "@/plugins/authentik";
import { vueQueryPluginOptions } from "@/plugins/vueQuery";
import vuetify from "@/plugins/vuetify";
import { loadFonts } from "@/plugins/webfontloader";
import router from "@/router";
import pinia from "@/store";

export const registerPlugins = (app: App) => {
  loadFonts();
  app
    .use(pinia)
    .use(router)
    .use(vuetify)
    .use(eccaComponents)
    .use(VueQueryPlugin, vueQueryPluginOptions);
  authentikPluginSetup(app);
};

import { SnackbarProps, useAppStore } from "@/store/app";

export declare type NotificationType = "info" | "success" | "warning" | "error";

const iconMap = {
  info: "$CircleInformation",
  success: "$CircleCheck",
  warning: "$TriangleExclamation",
  error: "$TriangleExclamation",
};

export const useSnackbar = () => {
  const notificationItem = (
    type: NotificationType,
    text: string,
    props?: Partial<Record<keyof SnackbarProps, any>>,
  ) => {
    const defaultTimeout = 3200;

    const snackProps = { ...props } as SnackbarProps;

    const disappearingTypes = ["info", "success"];
    if (disappearingTypes.includes(type) && snackProps?.timeout === undefined) {
      snackProps.timeout = defaultTimeout;
    }

    const closableTypes = ["info", "warning", "error", "success"];
    if (snackProps?.closeIcon === undefined) {
      snackProps.closeIcon = closableTypes.includes(type);
    }

    if (snackProps.icon === undefined) snackProps.icon = iconMap[type];

    return {
      type,
      text,
      ...snackProps,
    };
  };

  const pushNotification = (
    type: NotificationType,
    text: string,
    props?: Partial<Record<keyof SnackbarProps, any>>,
  ) => {
    const appStore = useAppStore();
    appStore.addNotifications(notificationItem(type, text, props));
  };

  return {
    notificationItem,
    pushNotification,
  };
};

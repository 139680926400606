import { AdaptationStatus, IntentPhaseTypes } from "@/modules/types";

const adaptationStatusDetails: Partial<Record<AdaptationStatus, any>> = {
  draft: {
    title: "Draft",
  },
  ready: {
    title: "Ready to publish",
  },
  sent: {
    title: "Sent to syndication",
  },
  default: {
    title: "Unknown status",
  },
};

export const config = {
  apiUrl: import.meta.env.VITE_API_URL,
  eccaApiUrl: import.meta.env.VITE_ECCA_API_URL,
  mockApiUrl: import.meta.env.VITE_MOCK_API_URL,
  thorApiUrl: import.meta.env.VITE_THOR_API_URL,
  playbookEditorUrl: import.meta.env.VITE_PLAYBOOK_EDITOR_URL,
  playbookCDNUrl: import.meta.env.VITE_PLAYBOOK_CDN_URL,
  reportEditorUrl: import.meta.env.VITE_PLAYBOOK_EDITOR_URL,
  reportCDNUrl: import.meta.env.VITE_PLAYBOOK_CDN_URL,
  socketUrl: import.meta.env.VITE_SOCKET_URL,
  assetsUrl: import.meta.env.VITE_ASSETS_URL,
  drawer: {
    railWidth: 98,
    width: 200,
  },
  topNavigation: {
    height: 65,
  },
  adaptationStatusDetails,
  skl: {
    intentPhasesTypes: [
      "awareness",
      "exploration",
      "consideration",
      "conversion",
    ],
    intentPhases: [
      {
        id: 1,
        key: "awareness" as IntentPhaseTypes,
        title: "Awareness",
        color: "#FFC6C6",
      },
      {
        id: 2,
        key: "exploration" as IntentPhaseTypes,
        title: "Exploration",
        color: "#A2F8E7",
      },
      {
        id: 3,
        key: "consideration" as IntentPhaseTypes,
        title: "Consideration",
        color: "#CBE3FF",
      },
      {
        id: 4,
        key: "conversion" as IntentPhaseTypes,
        title: "Conversion",
        color: "#FFCF71",
      },
    ],
  },
};

import { Directive, DirectiveBinding } from "vue";

export const blurSave: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding, vnode: any) {
    const callback = binding.value;

    el.addEventListener(
      "blur",
      ($event) => {
        let previousValue = undefined;
        let currentValue = undefined;

        switch (vnode.type) {
          case "div":
            previousValue = vnode.ctx.setupState?.defaultValue;
            currentValue = ($event.target as HTMLDivElement).innerText;
            break;
          case "input":
            previousValue = vnode.ctx.proxy.modelValue;
            currentValue = ($event.target as HTMLInputElement).value;
            break;
          default:
            break;
        }

        if (previousValue === currentValue) return;
        callback($event);
      },
      { capture: true },
    );
  },
};

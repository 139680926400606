import * as Sentry from "@sentry/vue";
import { App } from "vue";
import { config } from "@/data/config";
import router from "@/router";

export const registerSentryClient = (app: App) => {
  const dsn = import.meta.env.VITE_SENTRY_DSN;

  if (!dsn) return;

  const monitoringConfig = (mode?: string) => {
    const conf = {
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.5, // This sets the sample rate. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      profilesSampleRate: 1.0,
    };

    if (mode === "production") {
      conf.tracesSampleRate = 0.1; //  Capture 10% of the transactions
      conf.profilesSampleRate = 0.2;
    }

    return conf;
  };

  Sentry.init({
    app,
    dsn,
    enableTracing: true,
    environment: import.meta.env.MODE,
    release: __APP_VERSION__,
    tracePropagationTargets: [config.eccaApiUrl],
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({}),
    ],
    ...monitoringConfig(import.meta.env.MODE),
  });
};

import { computed } from "vue";

export const useModelValueWrapper = (
  props: any,
  emit: any,
  name: string = "modelValue",
) =>
  computed({
    get: () => props[name],
    set: (value) => emit(`update:${name}`, value),
  });

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import {
  eccaThemes,
  aliases as eccaAliases,
  eccaIcons,
} from "@ecca/ui-components";
import { createVuetify } from "vuetify";
import { aliases as mdiAliases, mdi } from "vuetify/iconsets/mdi-svg";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: "greenTheme",
    themes: eccaThemes,
  },
  icons: {
    defaultSet: "mdi",
    aliases: {
      ...mdiAliases,
      ...eccaAliases,
    },
    sets: {
      mdi,
      eccaIcons,
    },
  },
});

export const reportsRouteBasePath = `/reports`;
export const previewRouteBasePath = `/preview`;

export const reportsRoutesNames = {
  root: "reports",
  single: () => `${reportsRoutesNames.root}.report`,
  list: () => `${reportsRoutesNames.root}.reports`,
};

export const reportsRoutes: any = [
  {
    path: reportsRouteBasePath,
    component: () => import("@/modules/reports/views/layouts/Default.vue"),
    meta: {
      auth: true,
      scope: ["reporting"],
    },
    children: [
      {
        path: reportsRouteBasePath,
        name: reportsRoutesNames.root,
        component: () => import("@/modules/reports/views/ReportsList.vue"),
      },
    ],
  },
  {
    path: `${reportsRouteBasePath}/:id`,
    component: () => import("@/modules/reports/views/layouts/Preview.vue"),
    meta: {
      auth: true,
      scope: ["reporting"],
    },
    children: [
      {
        path: `${reportsRouteBasePath}/:id`,
        name: reportsRoutesNames.single(),
        component: () => import("@/modules/reports/views/Report.vue"),
      },
    ],
  },
];

import {
  THEME_BLUE,
  THEME_CYAN,
  THEME_GREEN,
  THEME_ORANGE,
} from "@/data/constants";
import { channelsRouteBasePath } from "@/modules/channels/routes";
import { contentRoutesNames } from "@/modules/content/routes";
import { productRoutesNames } from "@/modules/products/routes";
import { reportsRouteBasePath } from "@/modules/reports/routes";
import router from "@/router";

export const drawerDefaultItems = () => {
  return [
    {
      title: "Products",
      icon: "$Package",
      link: router.resolve({
        name: productRoutesNames.root,
      }),
      routeName: "products",
      theme: THEME_GREEN,
    },
    {
      title: "Reports",
      icon: "$Strategy",
      link: reportsRouteBasePath,
      routeName: "reports",
      theme: THEME_CYAN,
    },
    {
      title: "Content",
      icon: "$Template",
      link: router.resolve({
        name: contentRoutesNames.root,
      }),
      routeName: contentRoutesNames.root,
      theme: THEME_BLUE,
    },
    {
      title: "Channels",
      icon: "$ArrowsRightLeft",
      link: channelsRouteBasePath,
      routeName: "channels",
      theme: THEME_ORANGE,
    },
  ];
};

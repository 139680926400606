import { createApp } from "vue";
import App from "@/App.vue";
import { registerPlugins } from "@/plugins";
import { registerSentryClient } from "@/plugins/sentry";
import "@ecca/ui-components/dist/style.css";
import { blurSave } from "@/shared/directives/blurSave";

const app = createApp(App);
registerSentryClient(app);
registerPlugins(app);

app.config.globalProperties.window = window;

app.mount("#app");

app.directive("blur-save", blurSave);

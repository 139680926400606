import Authentik from "@ecca/vue-auth-plugin";
import { App } from "vue";

const authentikIssuer = import.meta.env.VITE_AUTHENTIK_ISSUER;
const authentikClientId = import.meta.env.VITE_AUTHENTIK_CLIENT_ID;

export const authentikPluginSetup = (app: App) => {
  const initPlugin = (app: App) => {
    // if (import.meta.env.MODE !== "development") {
    //   if (!authentikIssuer || !authentikClientId) {
    //     throw new Error("Authentik env vars not set");
    //   }

    app.use(Authentik, {
      clientID: authentikClientId,
      issuer: authentikIssuer,
    });
    // }
  };
  initPlugin(app);
};

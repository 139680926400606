<script lang="ts" setup>
import { computed } from "vue";
import { IdType } from "@/modules/types";
import { useModelValueWrapper } from "@/shared/hooks/useModelValueWrapper";
import UiDialog from "@/shared/ui/UiDialog.vue";

const props = defineProps<{
  dialogVisible?: any;
  currentWrittenContent: IdType;
}>();

const emit = defineEmits([
  "update:dialogVisible",
  "aiWrittenContentCancelProcess",
]);

const dialogVisibleMV = useModelValueWrapper(props, emit, "dialogVisible");

const onClick = () => {
  dialogVisibleMV.value = false;
  emit("aiWrittenContentCancelProcess");
};
const title = computed(() => {
  return `Are you sure you want to cancel?`;
});
</script>
<template>
  <UiDialog v-model:dialog-visible="dialogVisibleMV" :title="title">
    <EccaWrapper>
      <p class="text-center generate-ai-wc__info">
        Content is being generated, if you cancel, progress will not be saved.
      </p>

      <VContainer>
        <VRow>
          <VCol class="d-flex justify-center">
            <EccaBtn
              variant="outlined"
              class="mr-3"
              @click="dialogVisibleMV = false"
            >
              Back
            </EccaBtn>

            <EccaBtn @click="onClick"> Cancel </EccaBtn>
          </VCol>
        </VRow>
      </VContainer>
    </EccaWrapper>
  </UiDialog>
</template>

<style scoped>
.generate-ai-wc__info ul {
  display: inline-block;
}
</style>

import { retryStrategy } from "@/api/config";
import { MutationOptions, QueryOptions } from "@/api/types";

export const defaultOptions: QueryOptions = {
  enabled: true,
  retry: retryStrategy,
  params: {},
};

export const useQueryOptions = (options: QueryOptions): QueryOptions => {
  return {
    ...defaultOptions,
    ...options,
  };
};

export const useQueryKey = (options: QueryOptions): string => {
  const params = useQueryParams(options);
  return params.length > 1 ? `?${params}` : "";
};

export const useQueryParams = (options: QueryOptions): string => {
  let paramsString = "";

  if (options.params?.include) paramsString += resolveIncludeParam(options);

  return paramsString;
};

export const useMutationOptions = (options: MutationOptions) => {
  return {
    ...options,
  };
};

const resolveIncludeParam = (options: QueryOptions) => {
  if (options.params?.include?.length) {
    if (!Array.isArray(options.params?.include))
      options.params.include = [options.params?.include];

    return `include=${options.params?.include.join(",")}`;
  }
};

export const productsRouteBasePath = "/products";

export const productRoutesNames = {
  root: "products",
  details: () => `${productRoutesNames.root}.details`,
  writtenContentTab: () => `${productRoutesNames.root}.written-content`,
  richContentTab: () => `${productRoutesNames.root}.rich-content`,
  writtenContentEditor: {
    localizations: () => `${productRoutesNames.root}.wc-editor-localizations`,
    adaptations: () => `${productRoutesNames.root}.wc-editor-adaptations`,
  },
};

export const productsRoutes: any = [
  {
    path: productsRouteBasePath,
    component: () => import("@/modules/products/views/layouts/Default.vue"),
    meta: {
      auth: true,
      scope: ["products"],
    },
    children: [
      {
        path: productsRouteBasePath,
        name: productRoutesNames.root,
        component: () => import("@/modules/products/views/ProductsList.vue"),
      },
      {
        path: `${productsRouteBasePath}/:id`,
        name: productRoutesNames.details(),
        component: () => import("@/modules/products/views/ProductDetails.vue"),
      },
      {
        path: `${productsRouteBasePath}/:id/written-content`,
        name: productRoutesNames.writtenContentTab(),
        component: () => import("@/modules/products/views/ProductDetails.vue"),
        meta: {
          scope: ["contents:written_content"],
        },
      },
      {
        path: `${productsRouteBasePath}/:id/rich-content`,
        name: productRoutesNames.richContentTab(),
        component: () => import("@/modules/products/views/ProductDetails.vue"),
        meta: {
          scope: ["contents:rich"],
        },
      },
    ],
  },
  {
    path: productsRouteBasePath,
    component: () => import("@/modules/products/views/layouts/SubPage.vue"),
    meta: {
      auth: true,
      scope: ["contents:written_content"],
    },
    children: [
      {
        path: `${productsRouteBasePath}/:productId/written-content/localizations/:writtenContentLocalizationId?`, // TODO simplify url, remove /editor/
        name: productRoutesNames.writtenContentEditor.localizations(),
        component: () =>
          import("@/modules/products/views/WrittenContent/WrittenContent.vue"),
        meta: {
          label: "Localization",
          hasSubmenu: false,
        },
      },
      {
        path: `${productsRouteBasePath}/:productId/written-content/localizations/:writtenContentLocalizationId/adaptations/:writtenContentAdaptationId?`, // TODO simplify url, remove /editor/
        name: productRoutesNames.writtenContentEditor.adaptations(),
        component: () =>
          import("@/modules/products/views/WrittenContent/WrittenContent.vue"),
        meta: {
          label: "Adaptation",
          hasSubmenu: false,
        },
      },
    ],
  },
];

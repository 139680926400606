import { routeGuard } from "@ecca/vue-auth-plugin";
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import { channelsRoutes } from "@/modules/channels/routes";
import { clientRoutes } from "@/modules/client/routes";
import { contentRouteBasePath, contentRoutes } from "@/modules/content/routes";
import {
  productsRouteBasePath,
  productsRoutes,
} from "@/modules/products/routes";
import { reportsRouteBasePath, reportsRoutes } from "@/modules/reports/routes";
import { errorRoutes } from "@/shared/ui/pages/errors/routes";
import { useAppStore } from "@/store/app";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      auth: true,
    },
  },
  ...clientRoutes,
  ...productsRoutes,
  ...reportsRoutes,
  ...contentRoutes,
  errorRoutes,
  channelsRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

const isWorkInProgress = (to: RouteLocationNormalized) => {
  const store = useAppStore();

  if (store.editState.isWorkInProgress) {
    store.setCachedTargetRoute(to);
    store.setIsWorkInProgressModalOpen(true);
  }

  return store.editState.isWorkInProgress;
};

const beforeEach = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const store = useAppStore();

  store.setForbiddenError(false);

  if (isWorkInProgress(to)) {
    return next(false);
  }

  if (to.fullPath === "/") {
    if (store.auth("products")) next(productsRouteBasePath);
    if (store.auth("reporting")) next(reportsRouteBasePath);
    if (
      store.auth("contents:skl") ||
      store.auth("contents:storyboards") ||
      store.auth("contents:rich") ||
      store.auth("contents:written_content")
    )
      next(contentRouteBasePath);

    return next();
  }

  if (to.meta.scope) {
    const grandAccess = (to.meta.scope as string[]).some((el: string) =>
      store.auth(el),
    );

    if (!grandAccess) store.setForbiddenError(true);
  }

  void routeGuard(to, from, next);
};

router.beforeEach(beforeEach);

export default router;

import { authentik } from "@ecca/vue-auth-plugin";
import axios, { AxiosRequestConfig } from "axios";
import { errorHandler, responseHandler } from "@/api/errorHandler";
import { RequestParams } from "@/api/types";

const getHeaders = (params?: any) => {
  return {
    "content-type": params?.headers?.["content-type"] ?? "application/json",
    Authorization: `Bearer ${authentik.tokens?.access_token}`,
  };
};

export const getRequestConfig = (params?: any) => {
  const headers = getHeaders(params);
  const token = params?.CancelToken ?? null;
  delete params?.headers;
  delete params?.CancelToken;
  // params = { ...params, canary: true }; // TODO make it switchable
  const config: AxiosRequestConfig = {
    headers,
    params,
  };

  if (token) config.cancelToken = token;

  return config;
};

const getErrorResolverType = (params?: Partial<RequestParams>) => {
  return params?.errorResolverType ?? "default";
};

export const getRequest = async (endpoint: string, params?: RequestParams) => {
  try {
    const { data } = await axios.get(endpoint, getRequestConfig(params));
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

export const postRequest = async (
  endpoint: string,
  postData?: any,
  params?: RequestParams,
) => {
  try {
    const { data } = await axios.post(
      endpoint,
      postData,
      getRequestConfig(params),
    );
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

export const putRequest = async (
  endpoint: string,
  postData?: any,
  params?: RequestParams,
) => {
  try {
    const { data } = await axios.put(
      endpoint,
      new URLSearchParams(postData),
      getRequestConfig(params),
    );
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

export const patchRequest = async (
  endpoint: string,
  patchData?: any,
  params?: RequestParams,
) => {
  try {
    const { data } = await axios.patch(
      endpoint,
      new URLSearchParams(patchData),
      getRequestConfig(params),
    );
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

export const putBodyRequest = async (
  endpoint: string,
  putData?: any,
  params?: RequestParams,
) => {
  try {
    const { data } = await axios.put(
      endpoint,
      putData,
      getRequestConfig(params),
    );
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

export const putBodyS3Request = async (
  endpoint: string,
  putData?: any,
  params?: any,
) => {
  try {
    const { data } = await axios.put(endpoint, putData, {
      headers: {
        "x-amz-meta-clientid": authentik.userInfo?.clientID,
        "x-amz-meta-userid": authentik.userInfo?.sub,
      },
    });
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

export const putAssetRequest = async (
  endpoint: string,
  postData: any,
  type: string,
  params?: RequestParams,
) => {
  try {
    const { data } = await axios.put(endpoint, postData, {
      headers: {
        ...getHeaders(),
        "Content-Type": type,
      },
      params,
    });
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

export const deleteRequest = async (endpoint: string, params?: any) => {
  try {
    const { data } = await axios.delete(endpoint, {
      headers: getHeaders(),
      params,
    });
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

export const getBlobRequest = async (
  endpoint: string,
  params?: RequestParams,
) => {
  try {
    const { data } = await axios.get(endpoint, {
      ...getRequestConfig(params),
      responseType: "blob",
    });
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

export const postBlobRequest = async (
  endpoint: string,
  postData?: any,
  params?: RequestParams,
) => {
  try {
    const { data } = await axios.post(endpoint, postData, {
      ...getRequestConfig(params),
      responseType: "blob",
    });
    return responseHandler(data);
  } catch (error: any | ErrorConstructor) {
    return errorHandler(error, getErrorResolverType(params));
  }
};

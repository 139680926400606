<script setup lang="ts">
import ConfirmCancelAIGeneration from "@/modules/products/components/Dialogs/ConfirmCancelAIGeneration.vue";
import ProcessingAISnackbar from "@/modules/products/components/ProcessingAISnackbar.vue";
import { useProductsStore } from "@/modules/products/store";

const { writtenContent } = useProductsStore();
</script>

<template>
  <div v-for="(item, index) in writtenContent.aiTasks" :key="index">
    <ProcessingAISnackbar
      v-if="item"
      v-model:dialog-visible="item.visible"
      :position="index + 1"
      :sklId="item.skl_id"
      :key="'pas' + item.writtenContentId"
      :task-item="item"
      :product-recommendation-path="item.recommendation_file_path"
      :written-content-data="item.writtenContentData"
    />
    <ConfirmCancelAIGeneration
      v-if="item"
      v-model:dialog-visible="item.cancelVisible"
      :current-written-content="item.writtenContentId"
      :key="'ccag' + item.writtenContentId"
      @ai-written-content-cancel-process="item.cancelAction = true"
    />
  </div>
</template>

const templatesRouteBaseName = "templates";

export const templatesRoutesNames = {
  root: templatesRouteBaseName,
  details: () => `${templatesRoutesNames.root}.details`,
  creator: {
    templates: () => `${templatesRoutesNames.root}.templates`,
    localizations: () => `${templatesRoutesNames.root}.localizations`,
    adaptations: () => `${templatesRoutesNames.root}.adaptations`,
  },
  preview: () => `${templatesRoutesNames.root}.preview`,
};

export const templatesRoutes = (basePath: string): any => {
  const templatesRouteBasePath = `${basePath}/${templatesRouteBaseName}`;
  const creatorRouteBasePath = `${basePath}/${templatesRouteBaseName}-creator`;
  const previewRouteBasePath = `${basePath}/preview`;

  return [
    {
      path: templatesRouteBasePath,
      component: () =>
        import(
          "@/modules/content/components/templates/views/layouts/Default.vue"
        ),
      meta: {
        auth: true,
        scope: ["contents:rich"],
      },
      children: [
        {
          path: templatesRouteBasePath,
          name: templatesRoutesNames.root,
          component: () =>
            import(
              "@/modules/content/components/templates/views/TemplatesList.vue"
            ),
        },
        {
          path: `${templatesRouteBasePath}/:templateId`,
          name: templatesRoutesNames.details(),
          component: () =>
            import(
              "@/modules/content/components/templates/views/TemplateDetails.vue"
            ),
        },
      ],
    },
    {
      path: creatorRouteBasePath,
      component: () =>
        import(
          "@/modules/content/components/templates/views/layouts/Creator.vue"
        ),
      meta: {
        auth: true,
        scope: ["contents:rich:write"],
        label: "",
      },
      children: [
        {
          path: `${creatorRouteBasePath}/:templateId?`,
          name: templatesRoutesNames.creator.templates(),
          component: () =>
            import(
              "@/modules/content/components/templates/views/TemplateCreator.vue"
            ),
          meta: {
            label: "Template",
            hasSubmenu: false,
          },
        },
        {
          path: `${creatorRouteBasePath}/:templateId/localizations`,
          name: templatesRoutesNames.creator.localizations(),
          component: () =>
            import(
              "@/modules/content/components/templates/views/LocalizationCreator.vue"
            ),
          meta: {
            label: "Localization",
            hasSubmenu: false,
          },
        },
        {
          path: `${creatorRouteBasePath}/:templateId/localizations/:localizationId?`,
          name: templatesRoutesNames.creator.localizations(),
          component: () =>
            import(
              "@/modules/content/components/templates/views/LocalizationCreator.vue"
            ),
          meta: {
            label: "Localization",
            hasSubmenu: false,
          },
        },
        {
          path: `${creatorRouteBasePath}/:templateId/localizations/:localizationId/adaptations/:adaptationId?`,
          name: templatesRoutesNames.creator.adaptations(),
          component: () =>
            import(
              "@/modules/content/components/templates/views/AdaptationCreator.vue"
            ),
          meta: {
            label: "Adaptation",
            hasSubmenu: true,
          },
        },
      ],
    },
    {
      path: previewRouteBasePath,
      component: () =>
        import(
          "@/modules/content/components/templates/views/layouts/Preview.vue"
        ),
      meta: {
        auth: true,
        scope: ["contents:rich"],
      },
      children: [
        {
          path: `${previewRouteBasePath}-:type/:id`,
          name: templatesRoutesNames.preview(),
          component: () =>
            import(
              "@/modules/content/components/templates/views/TemplatePreview.vue"
            ),
        },
      ],
    },
  ];
};
